<template>
  <div>
    <div class="ant-modal-root order-detail_loader" v-if="fetching">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin></a-spin>
        </div>
      </div>
    </div>

    <a-modal
      v-if="order"
      :visible="!!order"
      @change="back"
      class="order-detail_modal"
      :footer="false"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="font-weight-bold">
              {{ order.order_id }}
            </div>
            <div class="small mb-n2">
              <OrderStatus :status="order.order_state" type="ping" class="ml-0" />

              <span class="text-muted ml-3">
                {{ $moment(order.order_date).format('DD MMMM YYYY HH:mm') }}
              </span>
            </div>
          </div>
          <div>
            <a-button
              class="mr-5"
              v-if="isDistributor && ['new', 'confirmed'].includes(order.order_state)"
              @click.prevent="() => (updateStatusFormVisibility = true, currentRecordToUpdateState = { order_number: order.order_id, order_state: order.order_state })"
            >
              {{ $t('menu.order.detail.change_status') }}
            </a-button>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="col-sm-6 mb-3">
          <h5> {{ $t('sales.customerInfo') }} </h5>

          <dl class="">
            <dt class="col-sm-3"> {{ $t('sales.name') }} </dt>
            <dd class="col-sm-8">{{ order.customer_info.name }}</dd>

            <dt class="col-sm-3"> {{ $t('sales.phone') }} </dt>
            <dd class="col-sm-8">{{ order.customer_info.phone }}</dd>

            <dt class="col-sm-3"> {{ $t('sales.email') }} </dt>
            <dd class="col-sm-8">{{ order.customer_info.email }}</dd>
          </dl>
        </div>

        <div class="col-sm-6 mb-3">
          <h5> {{ $t('sales.paymentInformation') }} </h5>

          <dl>
            <dt> {{ $t('sales.totalPayment') }} </dt>
            <dd>{{ order.payment_info.gross_amount | currency }}</dd>

            <dt> {{ $t('sales.paymentStatus') }} </dt>
            <dd>{{ order.payment_info.payment_status }}</dd>

            <dt> {{ $t('sales.paymentMethod') }} </dt>
            <dd>
              <div>
                {{ order.payment_info.payment_method }}
              </div>
              <div>
                {{ order.payment_info.va_number }}
              </div>
            </dd>
          </dl>
        </div>
        <div class="col-sm-12">
          <h5 class=""> {{ $t('sales.shippingInforamtion') }} </h5>

          <div class="mb-1">
            <strong>{{ order.shipping_info.name }}</strong>
          </div>
          <div>
            {{ String(order.shipping_info.address).trim() }}
          </div>
          <div>
            {{
              [
                order.shipping_info.sub_district,
                order.shipping_info.district,
                order.shipping_info.city,
                order.shipping_info.province,
              ].join(', ')
            }}
          </div>
          <div>{{ order.shipping_info.country }} - {{ order.shipping_info.postal_code }}</div>
          <div>{{ order.shipping_info.phone }}</div>

          <div class="mt-1 font-weight-bold">
            {{ order.shipping_info.logistic.name }} {{ order.shipping_info.logistic.service }}
          </div>
          <div class="mt-1 text-muted">
            <div> AWB No: </div>
            <div> {{ order.shipping_info.logistic.tracking_no }} </div>
          </div>
        </div>

      </div>

      <div class="order-detail__items">
        <div class="order-detail__items__header">
          <div class=""> {{ $t('sales.productName') }} </div>
          <!-- <div class="d-none d-sm-table-cell">Price</div> -->
          <div class="order-detail__items__header__amount"> {{ $t('sales.amount') }} </div>
        </div>
        <div
          v-for="item in order.order_items"
          :key="item.uuid"
          class="order-detail__items__item"
        >
          <div>
            <div class="media">
              <img :src="`${item.image_url}?tr=w-250,h-250,cm-pad_resize,bg-FFFFFF`" class="mr-3 img-thumbnail" />
              <div class="media-body">
                <div class="font-weight-bold">{{ item.product_title }}</div>
                <div class="text-muted small mt-2">
                  <div>SKU: {{ item.sku }}</div>
                </div>
                <div v-if="Number(item.discount)">
                  <div style="text-decoration: line-through;"> {{ item.price | currency }} </div>
                  <div class="text-muted"> Discount {{ Number(item.discount) | currency }} </div>
                </div>
                <div class="text-muted small">
                  <div>Qty: {{ item.qty }} X {{ Number(item.item_sellingprice) * item.qty | currency }} </div>
                </div>
                <div class="text-muted small mt-2" v-if="item.voucher_value && item.voucher_code">
                  <div> {{ $t('sales.voucher') }} </div>
                  <div class="d-flex flex-row">
                    <strong> {{ item.voucher_code }}: </strong>
                    <div class="ml-3" style="color: red"> - {{ item.voucher_value | currency }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="d-none d-sm-table-cell">
            {{ Number(item.item_sellingprice) | currency }}
          </div> -->
          <div>
            {{ (Number(item.item_sellingprice) * item.qty) - (item.voucher_value) | currency }}
          </div>
        </div>
      </div>

      <dl class="row mt-3">
        <dt class="col-6">
          {{ $t('sales.totalItemPrice') }}
        </dt>
        <dd class="col-6 text-right">
          {{ totalItemPrice | currency }}
        </dd>

        <dt class="col-6">
          {{ $t('sales.shippingCost') }}
        </dt>
        <dd class="col-6 text-right">
          {{ shippingCost | currency }}
        </dd>

        <dt class="col-6">
          {{ $t('sales.insuranceCost') }}
        </dt>
        <dd class="col-6 text-right">
          {{ insuranceCost | currency }}
        </dd>

        <dt class="col-6 text-danger" v-if="discount > 0">
          {{ $t('sales.discount') }}
        </dt>
        <dd class="col-6 text-danger text-right"  v-if="discount > 0">
          {{ order.discount | currency }}
        </dd>

        <dt class="col-6" v-if="voucher.status">
          {{ $t('sales.voucher') }}
        </dt>
        <dd class="col-6 text-danger text-right"  v-if="voucher.status">
          - {{ order.voucher_value | currency }}
        </dd>

        <dt class="col-6">
          {{ $t('sales.total') }}
        </dt>
        <dd class="col-6 text-right font-weight-bold">
          {{ total | currency }}
        </dd>
      </dl>
    </a-modal>
    <UpdateStatusForm
      v-if="updateStatusFormVisibility"
      @closed="(updateStatusFormVisibility = false, currentRecordToUpdateState = null)"
      :record="currentRecordToUpdateState"
      @updated="handleUpdateStatus"
    />
  </div>
</template>

<script>
import OrderStatus from '@/components/Order/OrderStatus.vue'
import UpdateStatusForm from '@/components/Order/UpdateStatusForm'
import { mapGetters } from 'vuex'

export default {
  components: {
    OrderStatus,
    UpdateStatusForm,
  },
  data() {
    return {
      fetching: true,
      order: null,
      updateStatusFormVisibility: false,
      currentRecordToUpdateState: null,
      title: '',
    }
  },
  metaInfo () {
    return {
      title: this.title,
    }
  },
  computed: {
    ...mapGetters('user', ['isDropship', 'isDistributor']),

    totalItemPrice() {
      return !this.order ? 0 : this.order.order_items.reduce((acc, item) => {
        return acc + ((Number(item.item_sellingprice) * item.qty) - item.voucher_value)
      }, 0)
    },
    shippingCost() {
      return Number(this.order?.shipping_info?.logistic?.cost | 0)
    },
    insuranceCost() {
      return Number(this.order?.shipping_info?.logistic?.insurance_cost | 0)
    },
    discount() {
      return Number(this.order?.discount | 0)
    },
    total() {
      return this.totalItemPrice + this.shippingCost + this.insuranceCost - this.discount - this.voucher.value
    },
    voucher() {
      // eslint-disable-next-line
      return this.order?.voucher_value && this.order?.voucher_list ? {
        status: true,
        value: this.order.voucher_value,
      } : {
        status: false,
        value: this.order.voucher_value,
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, this.$parent.position.y || 0)
      document.querySelector('body').style.overflow = 'hidden'
    })

    this.getOrder()
  },
  beforeDestroy() {
    document.querySelector('body').style.overflow = 'auto'
  },
  watch: {
    order() {
      this.$nextTick(() => {
        const modal = document.querySelector('.order-detail_modal')

        if (modal) {
          const header = modal.querySelector('.ant-modal-header')
          const body = modal.querySelector('.ant-modal-body')
          const height = header.offsetHeight || 0

          body.style.maxHeight = `calc(100vh - ${height}px)`
        }
      })
    },
  },
  methods: {
    getOrder() {
      this.$store
        .dispatch('sales/fetchOrder', this.$route.params.id)
        .then(
          (response) => {
            this.order = response.data
            this.title = `Sales Order - ${this.order.order_id}`
          },
          (err) =>
            this.$message.error(err?.response?.message?.data || err?.message),
        )
        .finally(() => {
          this.fetching = false
        })
    },
    back() {
      this.$router.push({
        name: 'order.index',
        params: {
          status: this.$parent.statusState || 'all',
        },
        query: this.$route.query,
      })

      this.$nextTick(() => {
        document.querySelector('body').style.overflow = 'auto'
        window.scrollTo(0, this.$parent.position.y || 0)
      })
    },
    handleUpdateStatus () {
      this.getOrder()
    },
  },
}
</script>

<style lang="scss">
.order-detail_loader {
  .ant-spin-dot-item {
    background-color: #fff;
  }
}

.order-detail_modal {
  .ant-modal {
    margin: 0 0 0 auto !important;
    top: 0;
    padding-bottom: 0;

    &-content {
      height: 100vh;
      border-radius: 0;
    }

    &-body {
      overflow: auto;
    }
  }

  h5 {
    font-size: .9rem;
    color: #999;
    text-transform: uppercase;
  }
  dl {
    align-items: flex-start;

    dt,
    dd {
      padding-top: .3rem;
      padding-bottom: .3rem;
      margin-bottom: 0;
    }

    dt {
      text-transform: uppercase;
      font-size: 90%;
      line-height: 1.25;
    }

    &:not(.row) {
      dt,
      dd {
        padding: 0;
      }

      dd {
        margin-bottom: .5rem;
      }
    }
  }

}

.order-detail__items {
  margin-top: 3rem;
  display: table;

  &__header {
    display: table-header-group;

    > *  {
      display: table-cell;
      text-transform: uppercase;
      font-weight: 300;
      padding: .5rem;
      font-size: 85%;
      background: #f0f2f4;
    }

    &__amount {
      padding-right: 3.5rem !important;
    }
  }

  &__item {
    display: table-row;
    margin-left: -1rem;
    margin-right: -1rem;
    width: 100%;

    > div {
      display: table-cell;
      vertical-align: top;
      padding: .5rem .5rem;
      border-bottom: 1px solid #efefef;
      width: 100%;
    }
  }

  .media {
    img {
      max-width: 75px;
      width: 100%;
    }
  }
}
</style>
